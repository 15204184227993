<template>
<div class="page-reporte-utilidades-ventas">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Utilidades Por Venta" class="elevation-1 px-5 py-3">

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
              <v-row>
              <v-col sm="4" md="4" lg="4">
                <v-autocomplete v-model="model.id_sucursal" :items="sucursales" :hide-no-data="true"
                    :hide-selected="true"
                    item-text="nombre" item-value="_id" label="Sucursal"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" md="6" lg="6">
                  <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                    <template v-slot:activator="{ on }">
                        <v-text-field v-on="on" v-model="model.fecha_de" label="Fecha De" color="secondary" prepend-icon="event"
                        @blur="model.fecha_de = parse_date(model.fecha_de)" :rules="[rules.required, valida_fecha]">
                        </v-text-field>
                    </template>

                    <v-date-picker v-model="model.fecha_de" color="secondary" scrollable locale="es-mx" @input="menu1 = false">
                        <v-spacer />

                        <v-btn color="secondary" x-small @click="menu1 = false">
                        Cancelar
                        </v-btn>
                    </v-date-picker>
                    </v-menu>
              </v-col>
              <v-col sm="6" md="6" lg="6">
                  <v-menu offset-x ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                    <template v-slot:activator="{ on }">
                        <v-text-field v-on="on" v-model="model.fecha_a" label="Fecha A" color="secondary" prepend-icon="event"
                        @blur="model.fecha_a = parse_date(model.fecha_a)" :rules="[rules.required, valida_fecha]">
                        </v-text-field>
                    </template>

                    <v-date-picker v-model="model.fecha_a" color="secondary" scrollable locale="es-mx" @input="menu2 = false">
                        <v-spacer />

                        <v-btn color="secondary" x-small @click="menu2 = false">
                        Cancelar
                        </v-btn>
                    </v-date-picker>
                    </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="4" md="4" lg="4">
                <v-switch v-show="model.mostrar_detalle.toString()=='0'" v-model="model.solo_totales" label="Mostrar solo Totales" true-value="1" false-value="0"></v-switch>
              </v-col>
              <v-col sm="4" md="4" lg="4">
                <v-switch v-model="model.mostrar_detalle" label="Reporte Detallado por Artículo" true-value="1" false-value="0"></v-switch>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" @click.native="consultar()">
          <v-icon>search</v-icon> {{ "Consultar" }}
        </v-btn>
        <v-btn color="success" @click.native="generar_reporte()">
          <v-icon>done</v-icon> {{ "Generar Reporte" }}
        </v-btn>
      </v-card-actions>

      <v-container>
        <v-client-table ref="vuetable" :data="registros" :columns="columns" :options="options" 
          :key="keyTable"
          class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>
        </v-client-table>
      </v-container> 


        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

</div>
</template>

<script>

export default {
  components: {
  },
  watch: {
    model_filters: {
      handler(val) {
        this.$nextTick(() => {
          this.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        });
      },
      deep: true
    },


  },
  mounted: function() {
    if (!this.verificaPermiso('t.ventas.utilidad')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.model = this.clean_model();
    var today = window.moment().format("YYYY-MM-DD");
    this.model.fecha_de = today;
    this.model.fecha_a = today;
    this.get_sucursales();
  },
  data() {
    return {
        keyTable:"inicial",
        columns:[],
        options:{
          headings:{},
        },
        registros:[],
        sucursales:[],
        menu1:false,
        menu2:false,
        menu3:false,
        menu4:false,

      search_marca: null,
      tipos_precios: [],
      categorias:[],
      marcas:[],
      articulos:[],
      articulos_proveedores:[],
      fab: [],
      isLoading: false,
      articulos_encontrados: [],
      id_articulo: "",

      search: "",
      model_filters: "",

      tmp_fecha: "",
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Reportes",
          disabled: true,
          href: ""
        },
        {
          text: "Utilidades Por Venta",
          disabled: true,
          href: ""
        }
      ],

      modal: false,
      modal_articulos: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no valido";
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: []
    }
  },
  methods: {
    get_sucursales: function () {
    let data = {
        "selector": {
            "type":"sucursales",
            "estatus": {
                "$eq": "Activo"
            }
        },
        "fields": [
            "nombre", "_id"
        ]
    };

    window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
            this.sucursales = [];
            if (response.data.docs.length > 0)
                this.sucursales = response.data.docs;
                let todas = {
                    _id: '0',
                    nombre: 'TODAS'
                };
                this.sucursales.unshift(todas);
        })

        .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener las sucursales.",
                footer: ""
            });
        });
},
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    valida_time: function(time) {
      if(time!=null && time.toString()!=""){
            if(time.toString().length != 5)
                return "Hora no valida1";
            if(!time.match(/^\d{2}:(?:[0-5]\d)$/))
                return "Hora no valida2";
            var time_arr = time.split(":");
            if(time_arr.length!=2){
                return "Hora no valida3";
            }else{
                if(isNaN(time_arr[0]) || isNaN(time_arr[1])){
                    return "Hora no valida4";
                }
                if(parseInt(time_arr[0])<24 && parseInt(time_arr[1])<60)
                {
                    return true;
                } else{
                    return "Hora no valida5";
                }
            }
      }
      return true;

    },
    clean_model: function() {
      return {
        id_sucursal:"0",
        fecha_de: '',
        fecha_a: '',
        solo_totales:"0",
        mostrar_detalle:"0",
      };
    },
    formatNumber: function (x) {
      if (x == undefined) return 0;
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    },
    consultar: function(){
        if(this.$refs.form.validate()){
        try {
                window.dialogLoader.show('Espere un momento por favor..');
                this.registros = [];
                this.columns = [];
                window.axios
                    .post(process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_resumen_utilidades/", this.model)                    
                    .then(response => {                        
                        if(response.data){
                          for (var key in response.data) {
                            var ant_key = JSON.parse(JSON.stringify(key));
                            if(key.indexOf(".") != -1){ //Se quitan los puntos porque en columns lo toma como un subatributo                              
                              key = key.replaceAll(".","");
                              this.options.headings[key] = ant_key;                              
                            }
                            this.columns.push(key);
                            
                            if(key == "utilidad %"){
                              response.data[key] = this.formatNumber(parseFloat(response.data[key]).toFixed(2))+"%";
                            }else{
                              response.data[key] = "$"+this.formatNumber(parseFloat(response.data[ant_key]).toFixed(2));
                            }
                            
                          }
                          this.registros = [response.data];
                          this.keyTable = moment(new Date()).format("x"); //Refrescar tabla
                        }
                        
                    })
                    .catch(error => {
                      console.log("Error:", error);
                      if (error.response && error.response.data.error){                        
                        this.$swal({
                            type: "error",
                            title: "¡Sin Información!",
                            text: error.response.data.error,
                            footer: ""
                        });
                      } else {
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al realizar la consulta. Intente nuevamente.",
                            footer: ""
                        });
                      }
                      
                    }).then(()=>{
                      window.dialogLoader.hide();
                    });
            } catch (error) {
              console.log("ERRORS: ",error);
            }
        }
    },
    generar_reporte: function(){
        if(this.$refs.form.validate()){
        try {
                window.dialogLoader.show('Espere un momento por favor..');

                if(this.model.solo_totales == null)
                    this.model.solo_totales = "0";
                
                var url = process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_utilidades_ventas/";
                if(this.model.mostrar_detalle != null && this.model.mostrar_detalle.toString()=="1"){
                  url = process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_utilidades_ventas_detalle/";
                }
                 

                window.axios
                    .post(url, this.model, {                      
                        "Content-type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Allow": "POST",
                        "cache-control": "no-cache",
                        "responseType": "arraybuffer"
                    })
                    .then(response => {
                        var bytes = response.data;
                        let blob = new Blob([bytes], {
                                type: response.headers["content-type"]
                            }),
                            url = window.URL.createObjectURL(blob);

                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.download = "Utilidades Ventas.xlsx";
                        a.click();
                        a.parentNode.removeChild(a);
                    })
                    .catch(error => {
                    console.log(error);
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al generar el reporte. Intente nuevamente.",
                        footer: ""
                    });
                    }).then(()=>{
                    window.dialogLoader.hide();
                    });
            } catch (error) {
            console.log("ERRORS: ",error);
            }
        }
    },



  }
}
</script>
<style scoped>
.elevation-2 >>> .VueTables__row {
  text-align: right !important;
}
</style>